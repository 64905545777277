
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MAppBar",

  setup() {
    const offset = ref(0);
    const shadow = ref(false);

    const route = useRoute();

    onMounted(() => {
      handleScroll();
      addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => removeEventListener("scroll", handleScroll));

    const handleScroll = () => {
      offset.value = Math.min(window.scrollY / 64, 1);
      if (!shadow.value && window.scrollY >= 64) {
        shadow.value = true;
      }
      if (shadow.value && window.scrollY < 64) {
        shadow.value = false;
      }
    };

    return { shadow, offset, route };
  }
});
