
import { defineComponent } from "vue";
import { useHomePage } from "@/composable/useHomePage";

import MAppBar from "@/components/ui/mobile/app-bar/MAppBar.vue";
import MBottomNavigation from "@/components/ui/mobile/bottom-navigation/MNavigation.vue";
import PCalendar from "@/components/calendar/PCalendar.vue";
import MMain from "@/components/ui/mobile/MMain.vue";
import PNewspaper from "@/components/PNewspaper.vue";
import MKidList from "@/components/mobile/MKidList.vue";
import MApp from "@/components/ui/mobile/app/MApp.vue";
import MDividerHoriz from "@/components/ui/mobile/divider/MDividerHoriz.vue";
import MButton from "@/components/ui/mobile/buttons/MButton.vue";
import MIconButton from "@/components/ui/mobile/buttons/MIconButton.vue";
import MInstallApp from "@/components/ui/mobile/MInstallApp.vue";

export default defineComponent({
  name: "MHome",
  components: {
    MInstallApp,
    MIconButton,
    MButton,
    MDividerHoriz,
    MApp,
    MKidList,
    PNewspaper,
    MMain,
    PCalendar,
    MBottomNavigation,
    MAppBar
  },

  setup() {
    const homePage = useHomePage();

    return {
      date: homePage.date,
      route: homePage.route
    };
  }
});
